.article-title {
    font-size: 1.5rem; /* Adjust the size as needed */
    font-weight:600;
    color: #000;
    font-family:'Poppins', sans-serif;
    margin-bottom: 15px;
  }
  
  .article-content {
    font-size: 1.1rem; /* Adjust the size as needed */
    color: #2E2E2E;
    font-family: 'Poppins', sans-serif;
  }
  
  .list-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd; /* Border color */
    border-radius: 8px;
    background-color: white;
  }

  .no-shadow {
    box-shadow: none !important;
    border: none !important;
  }