.bulletPoints-list {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  color: white;
}

.bulletPoints-list li {
  display: flex;
  align-items: center; /* Aligns the icon with the text */
  margin-bottom: 16px; /* Spacing between list items */
}

.icon {
  margin-right: 8px; /* Space between icon and text */
  color: inherit; /* Icon color - you can change it as needed */
  font-size: 24px; /* Icon size */
}

.container-black {
    display: flex;
    background-color: black; /* orange background */
    padding: 60px 20px 20px 20px; /* Adjusted padding */
    box-sizing: border-box;
    align-items: center;
  }
  
  .bulletPoints ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0;
  }
  
  .bulletPoints ul li {
    font-size: 1.2em; /* Makes text larger */
    font-weight: normal; /* Makes text bold */
    margin-bottom: 10px; /* Adds space between the bullet points */
  }

  .bulletPoints-letters-white {
    color: white; /* Set text color to white */
  }

  .bulletPoints-letters-orange {
    color: #ff8600; /* Set text color to orange */
  }
  
  .imageSection {
    padding-right: 20px; /* Adds spacing between the image and the bullet points */
  }
  
  .imageSection img {
    width: 100%; /* Reduces the image size to 50% of its container */
    height: auto; /* Maintains the aspect ratio */
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .imageSection img {
      width: 100%; /* Adjusts the image width to 100% on smaller screens for better responsiveness */
    }
  
    .imageSection {
      padding-right: 0; /* Removes the right padding on smaller screens */
    }
  }