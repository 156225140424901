/* Account.css */
.AccountCard-container {
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Optional: adds shadow to make card pop out */
}

.AccountCard-content {
    padding: 20px; /* Adds padding inside the card */
}

.AccountCard-header {
    margin-bottom: 20px; /* Adds space below the card header */
}