/* Copyright.css */

.copy-right-text-white {
    color: white; /* Adjust color as needed */
    text-align: center;
  }

  .copy-right-link:hover {
    text-decoration: underline; /* Adds underline on hover for better user experience */
    text-align: center;
  }

  .copyright-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align */
    justify-content: center; /* Center justify in case of vertical spacing */
    text-align: center; /* Center the text of children */
  }
  
  .pdf-links {
    margin-bottom: 20px; /* Adjust spacing as needed */
  }
  
  .pdf-link {
    margin: 0 10px; /* Space out the PDF links */
    color: white; /* Example link color */
  }
  
  /* Additional styles as before */
  .bg-black {
    background-color: black;
  }
  
  .copy-right-text-white {
    color: white;
  }

