.container-black-m {
    display: flex;
    background-color: black; /* orange background */
    padding: 30px 20px 20px 20px; /* Adjusted padding */
    box-sizing: border-box;
    align-items: center;
  }
  
  .bulletPoints ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0;
  }
  
  .bulletPoints ul li {
    font-size: 1.2em; /* Makes text larger */
    font-weight: normal; /* Makes text bold */
    margin-bottom: 10px; /* Adds space between the bullet points */
  }
  
  .imageSection {
    padding-right: 20px; /* Adds spacing between the image and the bullet points */
  }
  
  .imageSection img {
    width: 100%; /* Reduces the image size to 50% of its container */
    height: auto; /* Maintains the aspect ratio */
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .imageSection img {
      width: 100%; /* Adjusts the image width to 100% on smaller screens for better responsiveness */
    }
  
    .imageSection {
      padding-right: 0; /* Removes the right padding on smaller screens */
    }
  }