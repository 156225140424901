.logout-button {
    background-color: var(--logout-button-bg-color);
    color: var(--logout-button-color);
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.logout-button:hover {
    background-color: #34495E; /* Slightly darker shade on hover */
}

.logout-button svg {
    fill: currentColor; /* Ensures the icon inside the button inherits the text color */
}