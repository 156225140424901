/* MarketplaceCard.css */
ul.bulletPoints-letters-white {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.bulletPoints-letters-white li {
  display: flex;
  align-items: center; /* Align icons with text */
  margin-bottom: 10px; /* Space between items */
}

.bulletPoints-letters-white li svg {
  margin-right: 10px; /* Space between icon and text */
  color: white; /* Icon color */
  font-size: 1.5rem; /* Icon size */
}

.container-o {
    display: flex;
    background-color: #ff8600; /* black background */
    padding: 60px 20px 20px 20px; /* Adjusted padding */
    box-sizing: border-box;
    align-items: center;
  }
  
  .bulletPoints ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0;
  }
  
  .bulletPoints ul li {
    font-size: 1.2em; /* Makes text larger */
    font-weight: normal; /* Makes text bold */
    margin-bottom: 10px; /* Adds space between the bullet points */
  }

  .bulletPoints-letters {
    color: black; /* Set text color to white */
  }

  .bulletPoints-letters-white {
    color: white; /* Set text color to orange */
  }
  
  .imageSection {
    padding-right: 20px; /* Adds spacing between the image and the bullet points */
  }
  
  .imageSection img {
    width: 100%; /* Reduces the image size to 50% of its container */
    height: auto; /* Maintains the aspect ratio */
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .imageSection img {
      width: 100%; /* Adjusts the image width to 100% on smaller screens for better responsiveness */
    }
  
    .imageSection {
      padding-right: 0; /* Removes the right padding on smaller screens */
    }

    .data-footer {
      background-color: black;
      color: white;
      padding: 10px 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    
    .data-footer span {
      font-weight: bold;
      font-size: 20px;
    }
  }